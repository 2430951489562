/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {getLayoutFromLocalStorage, ILayout, LayoutSetup} from '../../../_metronic/layout/core'

const RulesPage: React.FC = () => {
  return (
    <>
      <div className='col-12 mt-2'>
          <div className="card card-custom card-flush">
              <div className="card-header">
                  <h3 className="card-title">Title</h3>
              </div>
              <div className="card-body py-5">
                  Lorem Ipsum is simply dummy text...
              </div>
              <div className="card-footer">
                  Footer
              </div>
          </div>
      </div>

    </>
  )
}

export {RulesPage}
