/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {Link} from "react-router-dom";

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
            <>
                <div
                    className='d-flex align-items-center d-lg-none ms-20'
                    title='İçerik menüsünü görüntüle'
                >
                    <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                            <img alt='Logo' src='media/logos/whowin.png' className='h-70px' />

                    </div>
                </div>

                <div
                    className='d-flex align-items-center d-lg-none ms-18 mt-3'
                >
                <h5>Winner Who</h5>
                </div>
            </>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/files/fil002.svg'
        title='Maçlar'
        fontIcon='bi-clipboard-data'
      />
      <SidebarMenuItem
        to='/fav-teams'
        icon='/media/icons/duotune/general/gen029.svg'
        title='Favori Takımlarım'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
          to='/subscription'
          icon='/media/icons/duotune/finance/fin002.svg'
          title='Abonelik Bilgisi'
          fontIcon='bi-layers'
      />
      <SidebarMenuItem
          to='/contact'
          icon='/media/icons/duotune/communication/com007.svg'
          title='İletişim'
          fontIcon='bi-layers'
      />
      <SidebarMenuItem
          to='/rules'
          icon='/media/icons/duotune/files/fil004.svg'
          title='Şartlar & Koşullar'
          fontIcon='bi-layers'
      />
      <SidebarMenuItem
          to='/policy'
          icon='/media/icons/duotune/files/fil004.svg'
          title='Gizlilik Politikası'
          fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
