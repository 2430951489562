import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {InpageMatch} from './InpageMatch'

const InpageMatchWrapper: FC = () => {
  return (
    <>
    <a href="../dashboard"><span className='indicator-label'>{"<"} Geri Dön</span></a>
      <PageTitle breadcrumbs={[]}>Maç Detay</PageTitle>
      <InpageMatch />
    </>
  )
}

export default InpageMatchWrapper
