/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {getLayoutFromLocalStorage, ILayout, LayoutSetup} from '../../../_metronic/layout/core'

const ContactPage: React.FC = () => {
  return (
    <>
      <div className='col-12 mt-2'>

          <div className="mb-10">
              <label className="form-label">Default input</label>
              <input
                  type="text"
                  className="form-control"
                  placeholder="name@example.com"
              />
          </div>
          <div className="mb-10">
              <label className="form-label">Solid background style</label>
              <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="name@example.com"
              />
          </div>
          <div className="mb-10">
              <label className="form-label">White background style</label>
              <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="name@example.com"
              />
          </div>
          <a href="#" className="btn btn-primary">Gönder</a>
      </div>

    </>
  )
}

export {ContactPage}
