import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {RulesPage} from './RulesPage'

const RulesPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Şartlar & Koşullar</PageTitle>
      <RulesPage />
    </>
  )
}

export default RulesPageWrapper
