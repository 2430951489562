import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {ContactPage} from './ContactPage'

const ContactPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>İletişim</PageTitle>
      <ContactPage />
    </>
  )
}

export default ContactPageWrapper
