/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import $ from 'jquery';
import {Link} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

const validateEmail = (email:any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function regUser(){
  var rg_name = $('#regform_name').val();
  var rg_surname = $('#regform_surname').val();
  var rg_email = $('#regform_email').val();
  var rg_phone = $('#regform_phone').val();
  var rg_password = $('#regform_password').val();
  var rg_password2 = $('#regform_password2').val();

  if(rg_password !== undefined && rg_password.toString().length < 8 || rg_password2 !== undefined && rg_password2.toString().length < 8) {
    $('#insertAlert_area').removeAttr("style");
    $('#insertAlert_text').html("Şifrenizin minimum 8 karakter olması gerekir.");
    setTimeout(() => {
      $('#insertAlert_area').fadeOut(500);
    }, 2500);
    return;
  }

  if(rg_email !== undefined && validateEmail(rg_email)) {} else {
    $('#insertAlert_area').removeAttr("style");
    $('#insertAlert_text').html("Lütfen geçerli bir E-Posta adresi giriniz.");
    setTimeout(() => {
      $('#insertAlert_area').fadeOut(500);
    }, 2500);
    return;
  }


if($('#kt_login_toc_agree').is(":checked") && $('#kt_login_toc_agree2').is(":checked")) {
  fetch("https://enesaydogan.com.tr/analiz/api/regapi.php?type=regUser_f&name="+rg_name+"&surname="+rg_surname+"&email="+rg_email+"&phone="+rg_phone+"&password="+rg_password+"&password2="+rg_password2, {
    "method": "GET"
  })
      .then(response => response.json())
      .then(response => {
        console.log(response);
          if(response.status) {
            $('#insertAlert_area').removeAttr("style");
            $('#insertAlert_div').removeClass("alert-danger").addClass("alert-success");
            $('#insertAlert_text').html(response.message);
            setTimeout(() => {
              $('#insertAlert_div').removeClass("alert-success").addClass("alert-danger");
              $('#insertAlert_area').fadeOut(500);
            }, 2500);
          } else {
            $('#insertAlert_area').removeAttr("style");
            $('#insertAlert_text').html(response.message);
            setTimeout(() => {
              $('#insertAlert_area').fadeOut(500);
            }, 2500);
          }
      })
      .catch(err => { console.log(err);
      });
} else {
  $('#insertAlert_area').removeAttr("style");
  $('#insertAlert_text').html("Kayıt olmak için Şartlar & Koşullar ve Gizlilik Politikası'nı onaylamanız gerekir.");
  setTimeout(() => {
    $('#insertAlert_area').fadeOut(500);
  }, 2500);
}
  




}

export function Registration() {
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Kayıt Ol</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}


        <div id="insertAlert_area" style={{display:"none"}}>
          <div id="insertAlert_div" className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold' id="insertAlert_text">-</div>
          </div>
        </div>


      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Adınız</label>
        <input
        id="regform_name"
          placeholder='Lütfen adınızı giriniz'
          type='text'
          autoComplete='off'
          className={'form-control bg-transparent'}
        />
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Soyadınız</label>
        <input
        id="regform_surname"
          placeholder='Lütfen soyadınızı giriniz'
          type='text'
          autoComplete='off'
          className={'form-control bg-transparent'}
        />
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>E-Posta Adresi</label>
        <input
        id="regform_email"
          placeholder='Lütfen E-Posta adresinizi giriniz'
          type='email'
          autoComplete='off'
          className={'form-control bg-transparent'}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group Phone */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Telefon Numarası</label>
        <input
        id="regform_phone"
          placeholder='Lütfen Telefon Numaranızı giriniz'
          type='number'
          autoComplete='off'
          className={'form-control bg-transparent'}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Şifre</label>
          <div className='position-relative mb-3'>
            <input
            id="regform_password"
              type='password'
              placeholder='Lütfen şifre giriniz'
              autoComplete='off'
              className={'form-control bg-transparent'}
            />
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Şifreniz minimum 8 karakter, büyük harf, küçük harf içermelidir.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Şifre Tekrar</label>
        <input
        id="regform_password2"
          type='password'
          placeholder='Lütfen şifrenizi tekrar giriniz'
          autoComplete='off'
          className={'form-control bg-transparent'}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
          />
          <span>

            <a
              href='https://keenthemes.com/metronic/?page=faq'
              target='_blank'
              className='ms-1 link-primary'
            >
              Şartlar & Koşullar
            </a>
            {''} okudum ve kabul ediyorum.
          </span>
        </label>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree2'>
          <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree2'
          />
          <span>

            <a
                href='https://keenthemes.com/metronic/?page=faq'
                target='_blank'
                className='ms-1 link-primary'
            >
              Gizlilik Politikası
            </a>
            {''} okudum ve kabul ediyorum.
          </span>
        </label>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <span
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          onClick={regUser}
        >
          <span className='indicator-label'>Kayıt Ol</span>
          
        </span>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Geri Dön
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
