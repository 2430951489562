/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {getLayoutFromLocalStorage, ILayout, LayoutSetup} from '../../../_metronic/layout/core'
import {useParams} from "react-router-dom";
import $ from 'jquery';
import teamIconPaths from './custom-1.png';
const InpageMatch: React.FC = () => {
    const matchID = useParams().id;
    fetch("https://enesaydogan.com.tr/analiz/api/getInner.php?type=getInner&matchid="+matchID, {
        "method": "GET"
    })
        .then(response => response.json())
        .then(response => {
            console.log("FİRST");
            console.log(response);
            var res = response
            console.log(res)
            /*if(res.status === 2) {
                $('#matchStatus_badge').html('Maç Bitti! İY ('+res.fh_home_team+'-'+res.fh_away_team+') - MS ('+res.ft_home_team+'-'+res.ft_away_team+')');
            } else if(res.status === 1) {
                $('#matchStatus_badge').html('CANLI - '+res.elapsed+'\'');
            } else if(res.status === 0) {
                $('#matchStatus_badge').html('Maç Başlamadı');
            }*/
            console.log(res.leaguename);
            $('#ATTR_league_name').html(res.leaguename);
            $('#ATTR_league_logo').attr('src', res.leaguelogo);
            $('#ATTR_away_logo').attr('src',res.a_logo);
            $('#ATTR_away_name').html(res.a_name);
            $('#ATTR_home_logo').attr('src',res.h_logo);
            $('#ATTR_home_name').html(res.h_name);
            $('.home-score').html(res.h_goal);
            $('.away-score').html(res.a_goal);
            setAnalytics();
        })
        .catch(err => { console.log(err);
        });


    function setAnalytics() {
        fetch("https://enesaydogan.com.tr/analiz/api/getInner.php?type=getInner&matchid="+matchID, {
            "method": "GET"
        })
        .then(response => response.json())
        .then(response => {
            response = JSON.parse(response.analiz);
            $('#evTakim_progress').attr("aria-valuenow", response.homeTeam_percent);
            $('#deplasman_progress').attr("aria-valuenow", response.awayTeam_percent);
            $('#evTakim_progress').attr("style", "width:"+response.homeTeam_percent+"%;");
            $('#deplasman_progress').attr("style", "width:"+response.awayTeam_percent+"%;");
            $('#deplasman_percent_date').html("%"+response.awayTeam_percent);
            $('#evTakim_percent_data').html("%"+response.homeTeam_percent);
            $('#recommendedTeam').html(""+response.recommendedTeam);
            $('#programTavsiye_text').html(response.recommendedText);
            $('#matchCategory').html(response.matchCategory);
            $('#matchTrust').html(response.matchTrust_percent)
            $('#ms1_percent').html("%"+response.ms1_percent.toString().substring(0, 4));
            $('#msx_percent').html("%"+response.msx_percent.toString().substring(0, 4));
            $('#ms2_percent').html("%"+response.ms2_percent.toString().substring(0, 4));
            $('#evSahibi_Son5_Win').html(""+(response.home_Last5win !== null ? response.home_Last5win : 0));
            $('#evSahibi_Son5_Beraberlik').html(""+(response.home_Last5tie !== null ? response.home_Last5tie : 0));
            $('#evSahibi_Son5_Maglup').html(""+(response.home_Last5lose !== null ? response.home_Last5lose : 0));
            $('#alternatifTahmin_text').html(response.recommendedTeam+""+response.alternateRecommended);
            $('#deplasman_Son5_Win').html(""+(response.away_Last5win !== null ? response.away_Last5win : 0));
            $('#deplasman_Son5_Beraberlik').html(""+(response.away_Last5tie !== null ? response.away_Last5tie : 0));
            $('#deplasman_Son5_Maglup').html(""+(response.away_Last5lose !== null ? response.away_Last5lose : 0));
            $('#KgVar_Percent_1').attr("style", "width:"+response.kg_var.toString().substring(0, 4)+"%;");
            $('#KgVar_Percent_2').html("VAR: %"+response.kg_var.toString().substring(0, 4))
            $('#KgYok_Percent_1').attr("style", "width:"+response.kg_yok.toString().substring(0, 4)+"%;");
            $('#KgYok_Percent_2').html("YOK: %"+response.kg_yok.toString().substring(0, 4))
            $('#totalGoals_01').html("%"+response.totalGoals_01);
            $('#totalGoals_23').html("%"+response.totalGoals_23);
            $('#totalGoals_45').html("%"+response.totalGoals_45);
            $('#alt_1_5').html("%"+response.alt_1_5);
            $('#ust_1_5').html("%"+response.ust_1_5);
            $('#alt_2_5').html("%"+response.alt_2_5);
            $('#ust_2_5').html("%"+response.ust_2_5);
            $('#alt_3_5').html("%"+response.alt_3_5);
            $('#ust_3_5').html("%"+response.ust_3_5);
            $('#iy_1_percent').html("%"+response.iy_1_percent.toString().substring(0, 4));
            $('#iy_x_percent').html("%"+response.iy_x_percent.toString().substring(0, 4));
            $('#iy_2_percent').html("%"+response.iy_2_percent.toString().substring(0, 4));
            $('#IYMS_MS1_MS1').html("%"+response.IYMS_MS1_MS1);
            $('#IYMS_MS1_MSX').html("%"+response.IYMS_MS1_MSX);
            $('#IYMS_MS1_MS2').html("%"+response.IYMS_MS1_MS2);
            $('#IYMS_MSX_MS1').html("%"+response.IYMS_MSX_MS1);
            $('#IYMS_MSX_MSX').html("%"+response.IYMS_MSX_MSX);
            $('#IYMS_MSX_MS2').html("%"+response.IYMS_MSX_MS2);
            $('#IYMS_MS2_MS1').html("%"+response.IYMS_MS2_MS1);
            $('#IYMS_MS2_MSX').html("%"+response.IYMS_MS2_MSX);
            $('#IYMS_MS2_MS2').html("%"+response.IYMS_MS2_MS2);


            /* Sayfa yüklendi ok ver */
            $('#pleaseWait_badge').fadeOut(500).remove();
            $('#contentArea_id').fadeIn(1000);
        })
        .catch(err => { console.log(JSON.stringify(err));
        });
    }


    function tabRemoves(tab:any) {
        if($( "#kt_tab_pane_"+tab ).hasClass( "active" )) {
            $('#kt_tab_pane_'+tab).removeClass("active");
        }
        if($( "#kt_tab_pane_"+tab ).hasClass( "show" )) {
            $('#kt_tab_pane_'+tab).removeClass("show");
        }
        if($("#kt_tab_pane_"+tab).attr("style") === undefined) {
            $("#kt_tab_pane_"+tab).attr("style", "display:none;");
        }
    }

    function changeTab(tab:any){
        if(tab === 1) {
            if(!$( "#kt_tab_pane_1" ).hasClass( "active" ) && !$( "#kt_tab_pane_1" ).hasClass( "show" )) {
                $( "#kt_tab_pane_1" ).addClass("active").addClass("show").removeAttr("style");
                tabRemoves(2); tabRemoves(3); tabRemoves(4); tabRemoves(5); tabRemoves(6); tabRemoves(7);
            }
        } else if(tab === 2) {
            if(!$( "#kt_tab_pane_2" ).hasClass( "active" ) && !$( "#kt_tab_pane_2" ).hasClass( "show" )) {
                $( "#kt_tab_pane_2" ).addClass("active").addClass("show").removeAttr("style");
                tabRemoves(1); tabRemoves(3); tabRemoves(4); tabRemoves(5); tabRemoves(6); tabRemoves(7);
            }
        } else if(tab === 3) {
            if(!$( "#kt_tab_pane_3" ).hasClass( "active" ) && !$( "#kt_tab_pane_3" ).hasClass( "show" )) {
                $( "#kt_tab_pane_3" ).addClass("active").addClass("show").removeAttr("style");
                tabRemoves(1); tabRemoves(2); tabRemoves(4); tabRemoves(5); tabRemoves(6); tabRemoves(7);
            }
        } else if(tab === 4) {
            if(!$( "#kt_tab_pane_4" ).hasClass( "active" ) && !$( "#kt_tab_pane_4" ).hasClass( "show" )) {
                $( "#kt_tab_pane_4" ).addClass("active").addClass("show").removeAttr("style");
                tabRemoves(1); tabRemoves(2); tabRemoves(3); tabRemoves(5); tabRemoves(6); tabRemoves(7);
            }
        } else if(tab === 5) {
            if(!$( "#kt_tab_pane_5" ).hasClass( "active" ) && !$( "#kt_tab_pane_5" ).hasClass( "show" )) {
                $( "#kt_tab_pane_5" ).addClass("active").addClass("show").removeAttr("style");
                tabRemoves(1); tabRemoves(2); tabRemoves(3); tabRemoves(4); tabRemoves(6); tabRemoves(7);
            }
        } else if(tab === 6) {
            if(!$( "#kt_tab_pane_6" ).hasClass( "active" ) && !$( "#kt_tab_pane_6" ).hasClass( "show" )) {
                $( "#kt_tab_pane_6" ).addClass("active").addClass("show").removeAttr("style");
                tabRemoves(1); tabRemoves(2); tabRemoves(3); tabRemoves(4); tabRemoves(5); tabRemoves(7);
            }
        } else if(tab === 7) {
            if(!$( "#kt_tab_pane_7" ).hasClass( "active" ) && !$( "#kt_tab_pane_7" ).hasClass( "show" )) {
                $( "#kt_tab_pane_7" ).addClass("active").addClass("show").removeAttr("style");
                tabRemoves(1); tabRemoves(2); tabRemoves(3); tabRemoves(4); tabRemoves(5); tabRemoves(6);
            }
        }
    }

  return (
    <>
        <div id="pleaseWait_badge" className="alert alert-primary d-flex align-items-center p-5 mb-10">
            <span className="svg-icon svg-icon-2hx svg-icon-primary me-3"><span className="spinner-border spinner-border-xl align-middle"></span></span>

            <div className="d-flex flex-column">
                <h5 className="mt-2">Maç analizi yükleniyor, lütfen bekleyin..</h5>
            </div>
        </div>
        <div id="contentArea_id" className='col-12 mt-2' style={{display:"none"}}>
            <div className="card card-custom card-flush">
                <ul className="nav nav-tabs nav-line-tabs mb-5 text-center fs-6" style={{overflowY:"hidden",overflowX:"scroll",whiteSpace:"nowrap",flexWrap:"nowrap"}}>
                    <li className="nav-item mx-auto" style={{marginBottom:0}} onClick={() => changeTab(1)}>
                        <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                        >
                            Maç Sonu
                        </a>
                    </li>
                    <li className="nav-item mx-auto" style={{marginBottom:0}} onClick={() => changeTab(2)}>
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                        >
                            Karşılıklı Gol
                        </a>
                    </li>
                    <li className="nav-item mx-auto" style={{marginBottom:0}} onClick={() => changeTab(3)}>
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                        >
                            Toplam Gol
                        </a>
                    </li>
                    <li className="nav-item mx-auto" style={{marginBottom:0}} onClick={() => changeTab(4)}>
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                        >
                            Alt/Üst
                        </a>
                    </li>
                    <li className="nav-item mx-auto" style={{marginBottom:0}} onClick={() => changeTab(5)}>
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                        >
                            İlk Yarı
                        </a>
                    </li>
                    <li className="nav-item mx-auto" style={{marginBottom:0}} onClick={() => changeTab(6)}>
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                        >
                            İY/MS
                        </a>
                    </li>
                    <li className="nav-item mx-auto" style={{marginBottom:0}} onClick={() => changeTab(7)}>
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                        >
                            Puan Durumu
                        </a>
                    </li>
                </ul>
                <div className="card-header">
                    <div className="inMatch_logodiv" style={{background:"white",borderRadius:"5%"}}>
                        <img className="inMatch_logo" id="ATTR_league_logo" src="https://media.api-sports.io/football/teams/19021.png" style={{padding:"10px 10px 10px 10px"}}></img>
                    </div>
                    <h3 className="card-title" id="ATTR_league_name">?</h3>
                </div>
                <div className="card-body py-5 p-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-5 text-center">
                                <img id="ATTR_home_logo" src="https://media.api-sports.io/football/teams/19021.png" className="rounded img-fluid" alt="..."/>
                                <p className="fs-2 p-5" id="ATTR_home_name">?</p>
                                <div className="h-8px mx-3 w-100 bg-danger rounded">
                                    <div id="evTakim_progress" className="bg-success rounded h-8px" role="progressbar" aria-valuenow={50}
                                         aria-valuemin={0} aria-valuemax={100} style={{width: '50%'}}></div>
                                </div>
                            </div>
                            <div className="col-2 d-flex text-center align-middle scoreText inMatch_scorelabel">
                                <p className="home-score">?</p>
                                <p className="center-line">-</p>
                                <p className="away-score">?</p>
                            </div>
                            <div className="col-5 text-center">
                                <img id="ATTR_away_logo" src="https://media.api-sports.io/football/teams/19021.png" className="rounded img-fluid" alt="..."/>
                                <p className="fs-2 p-5" id="ATTR_away_name">?</p>
                                <div className="h-8px mx-3 w-100 bg-danger rounded">
                                    <div id="deplasman_progress" className="bg-success rounded h-8px" role="progressbar" aria-valuenow={50}
                                         aria-valuemin={0} aria-valuemax={100} style={{width: '50%'}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center pt-5 fs-3 fw-bold">
                            <div className="col-4 pl7" id="evTakim_percent_data">?</div>
                            <div className="col-4 percentLabel_perf">Performans</div>
                            <div className="col-4 pr7" id="deplasman_percent_date">?</div>
                        </div>
                        <div className="row text-center pt-5 fs-3">
                            <div className="col-4">
                                    <p className="bg-light">1</p>
                                    <p className="bg-hover-info">14.00</p>
                            </div>
                            <div className="col-4">
                                    <p className="bg-light">X</p>
                                    <p className="bg-hover-info">14.00</p>
                            </div>
                            <div className="col-4">
                                    <p className="bg-light">2</p>
                                    <p className="bg-hover-info">14.00</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body py-5 p-3">
                    <div className="tab-pane fade active show" id="kt_tab_pane_1" role="tabpanel">
                        <div className="container-fluid">
                            <div className="container-fluid text-center fs-3 bg-info">
                                <p className="p-2" id="matchStatus_badge">Maç Sonu</p>
                            </div>
                            <div className="container rounded text-center fs-4">
                                <div className="row">
                                    <div className="col-4 bg-success">
                                        <p className="p-2 m-0" id="ms1_percent">%100</p>
                                    </div>
                                    <div className="col-4 bg-secondary">
                                        <p className="p-2 m-0" id="msx_percent">%100</p>
                                    </div>
                                    <div className="col-4 bg-danger">
                                        <p className="p-2 m-0" id="ms2_percent">%100</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid text-center fs-3 bg-light mt-3">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-4">
                                        <p>Program Önerisi</p>
                                        <p className="bg-info" id="recommendedTeam">2</p>
                                    </div>
                                    <div className="col-sm-12 col-lg-4">
                                        <p>Kategori</p>
                                        <p className="bg-info" id="matchCategory">-</p>
                                    </div>
                                    <div className="col-sm-12 col-lg-4">
                                        <p>Güven Seviyesi</p>
                                        <p className="bg-info" id="matchTrust">-</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid fs-3 bg-light mt-3 text-center">
                                <div className="row g-3">
                                    <div className="col-sm-12 col-lg-6">
                                        <p className="w-100 bg-secondary rounded-1 h-100 p-5 bg-hover-danger">Alternatif Tahmin</p>
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <p className="w-100 bg-secondary rounded-1 h-100 p-5 bg-hover-danger">Program Tavsiyesi</p>
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <p className="w-100 bg-secondary rounded-1 h-100 p-5 bg-hover-danger" id="alternatifTahmin_text">5</p>
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <p className="w-100 bg-secondary rounded-1 h-100 p-5 bg-hover-danger" id="programTavsiye_text">?</p>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid fs-3 bg-light mt-3 text-center">
                                <div className="container rounded text-center fs-4">
                                    <div className="row pt-5">
                                        <div className="col-4 bg-secondary">
                                            <p className="p-2 m-0">Evinde</p>
                                        </div>
                                        <div className="col-4 bg-info">
                                            <p className="p-2 m-0">Son 5 Maç</p>
                                        </div>
                                        <div className="col-4 bg-secondary">
                                            <p className="p-2 m-0">Deplasmanda</p>
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <div className="col-4 bg-secondary">
                                            <p className="p-2 m-0" id="evSahibi_Son5_Win">-</p>
                                        </div>
                                        <div className="col-4 bg-info">
                                            <p className="p-2 m-0">Galip</p>
                                        </div>
                                        <div className="col-4 bg-secondary">
                                            <p className="p-2 m-0" id="deplasman_Son5_Win">-</p>
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <div className="col-4 bg-secondary">
                                            <p className="p-2 m-0" id="evSahibi_Son5_Beraberlik">-</p>
                                        </div>
                                        <div className="col-4 bg-info">
                                            <p className="p-2 m-0">Berabere</p>
                                        </div>
                                        <div className="col-4 bg-secondary">
                                            <p className="p-2 m-0" id="deplasman_Son5_Beraberlik">-</p>
                                        </div>
                                    </div>

                                    <div className="row mt-1 pb-5">
                                        <div className="col-4 bg-secondary">
                                            <p className="p-2 m-0" id="evSahibi_Son5_Maglup">-</p>
                                        </div>
                                        <div className="col-4 bg-info">
                                            <p className="p-2 m-0">Mağlup</p>
                                        </div>
                                        <div className="col-4 bg-secondary">
                                            <p className="p-2 m-0" id="deplasman_Son5_Maglup">-</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel" style={{display:"none"}}>
                            <div className="container-fluid text-center fs-3 bg-info">
                                <p className="p-2" id="matchStatus_badge">Karşılıklı Gol</p>
                            </div>
                            <div className="container rounded text-center fs-4">
                                <div className="row">
                                    <div className="col-4 bg-success" id="KgVar_Percent_1">
                                        <p className="p-2 m-0" id="KgVar_Percent_2">-</p>
                                    </div>
                                    <div className="col-4 bg-danger" id="KgYok_Percent_1">
                                        <p className="p-2 m-0" id="KgYok_Percent_2">-</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel" style={{display:"none"}}>
                        <div className="container-fluid text-center fs-3 bg-info">
                            <p className="p-2" id="matchStatus_badge">Toplam Gol</p>
                        </div>
                        <div className="container rounded text-center fs-4">
                            <div className="row">
                                <div className="col-4 bg-primary">
                                    <p className="p-2 m-0"><b>0-1</b> GOL</p>
                                </div>
                                <div className="col-4 bg-primary">
                                    <p className="p-2 m-0"><b>2-3</b> GOL</p>
                                </div>
                                <div className="col-4 bg-primary">
                                    <p className="p-2 m-0"><b>4-5</b> GOL</p>
                                </div>
                                <div className="col-4 bg-secondary">
                                    <p className="p-2 m-0" id="totalGoals_01">%-</p>
                                </div>
                                <div className="col-4 bg-secondary">
                                    <p className="p-2 m-0" id="totalGoals_23">%-</p>
                                </div>
                                <div className="col-4 bg-secondary">
                                    <p className="p-2 m-0" id="totalGoals_45">%-</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel" style={{display:"none"}}>
                        <div className="container-fluid text-center fs-3 bg-info">
                            <p className="p-2" id="matchStatus_badge">Alt/Üst</p>
                        </div>
                        <div className="container rounded text-center fs-4">
                            <div className="row">
                                <div className="col-4 bg-primary">
                                    <p className="p-2 m-0"><b>Alt</b></p>
                                </div>
                                <div className="col-4 bg-primary">
                                    <p className="p-2 m-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                                <div className="col-4 bg-primary">
                                    <p className="p-2 m-0"><b>Üst</b></p>
                                </div>

                                <div className="col-4 bg-success">
                                    <p className="p-2 m-0" id="alt_1_5">%-</p>
                                </div>
                                <div className="col-4 bg-light">
                                    <p className="p-2 m-0">1.5 Gol</p>
                                </div>
                                <div className="col-4 bg-danger">
                                    <p className="p-2 m-0" id="ust_1_5">%-</p>
                                </div>

                                <div className="col-4 bg-success">
                                    <p className="p-2 m-0" id="alt_2_5">%-</p>
                                </div>
                                <div className="col-4 bg-light">
                                    <p className="p-2 m-0">2.5 Gol</p>
                                </div>
                                <div className="col-4 bg-danger">
                                    <p className="p-2 m-0" id="ust_2_5">%-</p>
                                </div>

                                <div className="col-4 bg-success">
                                    <p className="p-2 m-0" id="alt_3_5">%-</p>
                                </div>
                                <div className="col-4 bg-light">
                                    <p className="p-2 m-0">3.5 Gol</p>
                                </div>
                                <div className="col-4 bg-danger">
                                    <p className="p-2 m-0" id="ust_3_5">%-</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel" style={{display:"none"}}>
                        <div className="container-fluid text-center fs-3 bg-info">
                            <p className="p-2" id="matchStatus_badge">İlk Yarı Tahmini</p>
                        </div>
                        <div className="container rounded text-center fs-4">
                            <div className="row">
                                <div className="col-4 bg-primary">
                                    <p className="p-2 m-0"><b>1</b></p>
                                </div>
                                <div className="col-4 bg-primary">
                                    <p className="p-2 m-0"><b>X</b></p>
                                </div>
                                <div className="col-4 bg-primary">
                                    <p className="p-2 m-0"><b>2</b></p>
                                </div>

                                <div className="col-4 bg-success">
                                    <p className="p-2 m-0" id="iy_1_percent">%-</p>
                                </div>
                                <div className="col-4 bg-light">
                                    <p className="p-2 m-0" id="iy_x_percent">%-</p>
                                </div>
                                <div className="col-4 bg-danger">
                                    <p className="p-2 m-0" id="iy_2_percent">%-</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel" style={{display:"none"}}>
                        <div className="container-fluid text-center fs-3 bg-info">
                            <p className="p-2" id="matchStatus_badge">İlk Yarı / Maç Sonucu</p>
                        </div>
                        <div className="container rounded text-center fs-4">
                            <div className="row">
                                    <div className="col-4 bg-primary">
                                        <p className="p-2 m-0"><b>1-1</b></p>
                                    </div>
                                    <div className="col-4 bg-primary">
                                        <p className="p-2 m-0"><b>1-X</b></p>
                                    </div>
                                    <div className="col-4 bg-primary">
                                        <p className="p-2 m-0"><b>1-2</b></p>
                                    </div>
                                    <div className="col-4 bg-light">
                                        <p className="p-2 m-0" id="IYMS_MS1_MS1">%-</p>
                                    </div>
                                    <div className="col-4 bg-light">
                                        <p className="p-2 m-0" id="IYMS_MS1_MSX">%-</p>
                                    </div>
                                    <div className="col-4 bg-light">
                                        <p className="p-2 m-0" id="IYMS_MS1_MS2">%-</p>
                                    </div>

                                    <div className="col-4 bg-primary mt-2">
                                        <p className="p-2 m-0"><b>X-1</b></p>
                                    </div>
                                    <div className="col-4 bg-primary mt-2">
                                        <p className="p-2 m-0"><b>X-X</b></p>
                                    </div>
                                    <div className="col-4 bg-primary mt-2">
                                        <p className="p-2 m-0"><b>X-2</b></p>
                                    </div>
                                    <div className="col-4 bg-light">
                                        <p className="p-2 m-0" id="IYMS_MSX_MS1">%-</p>
                                    </div>
                                    <div className="col-4 bg-light">
                                        <p className="p-2 m-0" id="IYMS_MSX_MSX">%-</p>
                                    </div>
                                    <div className="col-4 bg-light">
                                        <p className="p-2 m-0" id="IYMS_MSX_MS2">%-</p>
                                    </div>

                                    <div className="col-4 bg-primary mt-2">
                                        <p className="p-2 m-0"><b>2-1</b></p>
                                    </div>
                                    <div className="col-4 bg-primary mt-2">
                                        <p className="p-2 m-0"><b>2-X</b></p>
                                    </div>
                                    <div className="col-4 bg-primary mt-2">
                                        <p className="p-2 m-0"><b>2-2</b></p>
                                    </div>
                                    <div className="col-4 bg-light">
                                        <p className="p-2 m-0" id="IYMS_MS2_MS1">%-</p>
                                    </div>
                                    <div className="col-4 bg-light">
                                        <p className="p-2 m-0" id="IYMS_MS2_MSX">%-</p>
                                    </div>
                                    <div className="col-4 bg-light">
                                        <p className="p-2 m-0" id="IYMS_MS2_MS2">%-</p>
                                    </div>


                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_7" role="tabpanel" style={{display:"none"}}>

                    </div>
                </div>
                <div className="card-footer">
                    Tüm hakları saklıdır, Winner Who 2022
                </div>
            </div>
        </div>

    </>
  )
}

export {InpageMatch}
