/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {getLayoutFromLocalStorage, ILayout, LayoutSetup} from '../../../_metronic/layout/core'

const SubPage: React.FC = () => {
  return (
    <>
      <div className='col-12 mt-2'>

      </div>

    </>
  )
}

export {SubPage}
