import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {PolicyPage} from './PolicyPage'

const PolicyPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Gizlilik Politikası</PageTitle>
      <PolicyPage />
    </>
  )
}

export default PolicyPageWrapper
