/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {KTSVG} from '../../../_metronic/helpers'
import $ from 'jquery';
import moment from 'moment';


const date = new Date().toISOString().slice(0, 10);
const defaultDate = new Date().toISOString().split('T')[0];
const defaultPercDate:any = new Date();
defaultPercDate.setDate(defaultPercDate.getDate() + 3)
const defaultPercDate_last = moment(defaultPercDate).format('YYYY-MM-DD')


$("body").on("click", "tr", function () {
    const matchid = $(this).attr('matchid');
    window.location.href = "match/"+matchid;
});

setTimeout(() => {
    const asd = fetch("https://enesaydogan.com.tr/analiz/api/getAll.php?type=getAll&date="+defaultDate+"&league=GLOBAL", {
            "method": "GET"
        })
        .then(response => response.json())
        .then(response => {
            setTimeout(() => {
                $('#waitBadge').attr("style", "display:none");
            }, 1000);
            
            var leagueArrays:any = [];
            $.each(response, function( key, value ) {
                if(value && value.analiz) {
                    var analizData = JSON.parse(value.analiz);
                    if (analizData.matchTrust_percent == "%0") return;
                    setTimeout(() => {
                        if(!leagueArrays.includes(value.leagueid)) {
                       
                            leagueArrays.push(value.leagueid);

                            $('#insertMatches_Area_1').append(`
                            <div class="d-flex mt-3">
                            <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                            </div>
                            
                            <div id="insertMatches_Area_1_`+value.leagueid+`"></div>
                            `);
                            if(analizData.matchCategory === "GÜVENLİ MAÇ") {
                            $('#insertMatches_Area_2').append(`
                            <div class="d-flex mt-3">
                            <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                            </div>
                            
                            <div id="insertMatches_Area_2_`+value.leagueid+`"></div>
                            `);
                            
                        }
                        if(analizData.matchCategory === "ORTA DERECE") {
                            $('#insertMatches_Area_3').append(`
                            <div class="d-flex mt-3">
                            <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                            </div>
                            
                            <div id="insertMatches_Area_3_`+value.leagueid+`"></div>
                            `);
                        }
                        if(analizData.matchCategory === "RİSKLİ MAÇ") {
                            $('#insertMatches_Area_4').append(`
                            <div class="d-flex mt-3">
                            <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                            </div>
                            
                            <div id="insertMatches_Area_4_`+value.leagueid+`"></div>
                            `);
                        }
                    }
                    var homeID = value.h_logo.match(/(\d+)\.png$/)[1];
                    var awayID = value.a_logo.match(/(\d+)\.png$/)[1];
                        $('#insertMatches_Area_1_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                    <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                    <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                    <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                    <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                    <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                    <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                </tr>`);
                        if(analizData.matchCategory === "GÜVENLİ MAÇ") {
                            $('#insertMatches_Area_2_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                    <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                    <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                    <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                    <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                    <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                    <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                </tr>`);
                        } else if(analizData.matchCategory === "ORTA DERECE") {
                            $('#insertMatches_Area_3_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                    <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                    <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                    <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                    <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                    <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                    <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                </tr>`);
                        } else if(analizData.matchCategory === "RİSKLİ MAÇ") {
                            $('#insertMatches_Area_4_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                    <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35 hTeam"/> </td>
                                    <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                    <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                    <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                    <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                    <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                </tr>`);
                        }

                       
                    }, 250);

                    
                    

                }
                
            });

        })
        .catch(err => { console.log(err);
        });
}, 2000);

function filterSomChanged(value:any,type:any) {
    /* Loading Bar & Clean */
    $('#insertMatches_Area_1').empty(); $('#insertMatches_Area_2').empty(); $('#insertMatches_Area_3').empty(); $('#insertMatches_Area_4').empty();
    $('#waitBadge').removeAttr("style");
    if(type === "countrySelected") {
        var getSelected_Date = $('#dateRange').val();
        console.log("cslect")
        if(value == "GLOBAL") {
            console.log("cglobal")
            fetch("https://enesaydogan.com.tr/analiz/api/getAll.php?type=getAll&date="+getSelected_Date+"&league="+$('#allCountries').val(), {
                "method": "GET"
            })
            .then(response => response.json())
            .then(response => {
                console.log(response);
                setTimeout(() => {
                    $('#waitBadge').attr("style", "display:none");
                }, 1000);
                var leagueArrays:any = [];
                $.each( response, function( key, value ) {
                    if(value && value.analiz) {
                        var analizData = JSON.parse(value.analiz);
                        if (analizData.matchTrust_percent == "%0") return;
                        setTimeout(() => {
                            if(!leagueArrays.includes(value.leagueid)) {
                           
                                leagueArrays.push(value.leagueid);
                                $('#insertMatches_Area_1').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_1_`+value.leagueid+`"></div>
                                `);
                                if(analizData.matchCategory === "GÜVENLİ MAÇ") {
                                $('#insertMatches_Area_2').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_2_`+value.leagueid+`"></div>
                                `);
                                
                            }
                            if(analizData.matchCategory === "ORTA DERECE") {
                                $('#insertMatches_Area_3').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_3_`+value.leagueid+`"></div>
                                `);
                            }
                            if(analizData.matchCategory === "RİSKLİ MAÇ") {
                                $('#insertMatches_Area_4').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_4_`+value.leagueid+`"></div>
                                `);
                            }
                        }
                        var homeID = value.h_logo.match(/(\d+)\.png$/)[1];
                        var awayID = value.a_logo.match(/(\d+)\.png$/)[1];

                            $('#insertMatches_Area_1_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            if(analizData.matchCategory === "GÜVENLİ MAÇ") {
                                $('#insertMatches_Area_2_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            } else if(analizData.matchCategory === "ORTA DERECE") {
                                $('#insertMatches_Area_3_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            } else if(analizData.matchCategory === "RİSKLİ MAÇ") {
                                $('#insertMatches_Area_4_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            }
                        }, 250);
                    }
                    
                });

            })
            .catch(err => { console.log(err);
            });
        } else if(value == "ULUSLA") {
            console.log("culus")
        } else {
            console.log("cself")
            console.log("asdasdas");
            console.log($('#allCountries').val())
            fetch("https://enesaydogan.com.tr/analiz/api/getAll.php?type=getAll&date="+getSelected_Date+"&league="+$('#allCountries').val(), {
                "method": "GET"
            })
            .then(response => response.json())
            .then(response => {
                console.log(response);
                setTimeout(() => {
                    $('#waitBadge').attr("style", "display:none");
                }, 1000);
                var leagueArrays:any = [];
                $.each( response, function( key, value ) {
                    if(value && value.analiz) {
                        var analizData = JSON.parse(value.analiz);
                        if (analizData.matchTrust_percent == "%0") return;
                        setTimeout(() => {
                            if(!leagueArrays.includes(value.leagueid)) {
                           
                                leagueArrays.push(value.leagueid);
                                $('#insertMatches_Area_1').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_1_`+value.leagueid+`"></div>
                                `);
                                if(analizData.matchCategory === "GÜVENLİ MAÇ") {
                                $('#insertMatches_Area_2').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_2_`+value.leagueid+`"></div>
                                `);
                                
                            }
                            if(analizData.matchCategory === "ORTA DERECE") {
                                $('#insertMatches_Area_3').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_3_`+value.leagueid+`"></div>
                                `);
                            }
                            if(analizData.matchCategory === "RİSKLİ MAÇ") {
                                $('#insertMatches_Area_4').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_4_`+value.leagueid+`"></div>
                                `);
                            }
                        }
                        var homeID = value.h_logo.match(/(\d+)\.png$/)[1];
                        var awayID = value.a_logo.match(/(\d+)\.png$/)[1];

                            $('#insertMatches_Area_1_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            if(analizData.matchCategory === "GÜVENLİ MAÇ") {
                                $('#insertMatches_Area_2_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            } else if(analizData.matchCategory === "ORTA DERECE") {
                                $('#insertMatches_Area_3_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            } else if(analizData.matchCategory === "RİSKLİ MAÇ") {
                                $('#insertMatches_Area_4_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            }
                        }, 250);
                        
                    }
                    
                });

            })
            .catch(err => { console.log(err);
            });
        }
       // fetchLeagues(value);
    } else if(type === "dateSelected") {
        var getSelected_Date = $('#dateRange').val();
        var countries = $('#allCountries').val();
        fetch("https://enesaydogan.com.tr/analiz/api/getAll.php?type=getAll&date="+getSelected_Date+"&league="+$('#allCountries').val(), {
                "method": "GET"
            })
            .then(response => response.json())
            .then(response => {
                console.log(response);
                setTimeout(() => {
                    $('#waitBadge').attr("style", "display:none");
                }, 1000);
                var leagueArrays:any = [];
                $.each( response, function( key, value ) {
                    if(value && value.analiz) {
                        var analizData = JSON.parse(value.analiz);
                        if (analizData.matchTrust_percent == "%0") return;
                        setTimeout(() => {
                            if(!leagueArrays.includes(value.leagueid)) {
                           
                                leagueArrays.push(value.leagueid);
                                $('#insertMatches_Area_1').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_1_`+value.leagueid+`"></div>
                                `);
                                if(analizData.matchCategory === "GÜVENLİ MAÇ") {
                                $('#insertMatches_Area_2').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_2_`+value.leagueid+`"></div>
                                `);
                                
                            }
                            if(analizData.matchCategory === "ORTA DERECE") {
                                $('#insertMatches_Area_3').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_3_`+value.leagueid+`"></div>
                                `);
                            }
                            if(analizData.matchCategory === "RİSKLİ MAÇ") {
                                $('#insertMatches_Area_4').append(`
                                <div class="d-flex mt-3">
                                <img src="`+value.leaguelogo+`" width="50"><h4 class="ml-2">`+value.leaguename+`</h4>
                                </div>
                                
                                <div id="insertMatches_Area_4_`+value.leagueid+`"></div>
                                `);
                            }
                        }
                        var homeID = value.h_logo.match(/(\d+)\.png$/)[1];
                        var awayID = value.a_logo.match(/(\d+)\.png$/)[1];

                            $('#insertMatches_Area_1_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            if(analizData.matchCategory === "GÜVENLİ MAÇ") {
                                $('#insertMatches_Area_2_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            } else if(analizData.matchCategory === "ORTA DERECE") {
                                $('#insertMatches_Area_3_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            } else if(analizData.matchCategory === "RİSKLİ MAÇ") {
                                $('#insertMatches_Area_4_'+value.leagueid).append(`<tr style="justify-content:center;text-align:center;cursor:pointer;" matchid="`+value.matchid+`">
                                        <td class="col-2 align-middle"> <img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${homeID}" class="team-logos w-35"/> </td>
                                        <td class="col-2 align-middle">`+value.h_name+` <br/> %`+analizData.homeTeam_percent+`</td>
                                        <td class="col-2 align-middle">`+(value.a_goal !== null ? "("+value.h_goal+"-"+value.a_goal+")" : "-/-")+`</td>
                                        <td class="col-2 align-middle">`+value.a_name+` <br/> %`+analizData.awayTeam_percent+`</td>
                                        <td class="col-2 align-middle"><img src="https://enesaydogan.com.tr/analiz/get_team_logo.php?team=${awayID}" class="team-logos w-35" /></td>
                                        <td class="col-2 align-middle" style="margin-top:1vh">`+analizData.matchTrust_percent+`</td>
                                    </tr>`);
                            }
                        }, 250);
                        
                    }
                    
                });

            })
            .catch(err => { console.log(err);
            });
    }
}

const DashboardPage: FC = () => (
    
    

  <>

    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>

      </div>
      {/* end::Col */}

    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='gx-1 gx-xl-1' style={{display:"inline-flex", flexWrap: "wrap", justifyContent: "space-around"}}>

      <div className='col-lg-6 col-xl-6 col-sm-6 col-6 ml35'>
          <input
              onChange={(e) => filterSomChanged(e.target.value,"dateSelected")}
              id="dateRange"
              type="date"
              className="form-control ms-3"
              data-date-format="DD-MM-YYYY"
              max={defaultPercDate_last}
              defaultValue={defaultDate}
          />
      </div>
      <div className='col-lg-6 col-xl-6 col-sm-6 col-6'>
        
          <select onChange={(e) => filterSomChanged(e.target.value,"countrySelected")} className="form-select ms-4" aria-label="Ülke Seçiniz" id="allCountries">
                <option value="GLOBAL">Tüm Ülkeler</option>
                <option value="ULUSLA">Uluslararası Maçlar</option>
                <option value="TR">Türkiye</option>
		        <option value="VI">ABD Virgin Adaları</option>
		        <option value="AF">Afganistan</option>
		        <option value="AX">Aland Adaları</option>
		        <option value="DE">Almanya</option>
		        <option value="US">Amerika Birleşik Devletleri</option>
		        <option value="UM">Amerika Birleşik Devletleri Küçük Dış Adaları</option>
		        <option value="AS">Amerikan Samoası</option>
		        <option value="AD">Andora</option>
		        <option value="AO">Angola</option>
		        <option value="AI">Anguilla</option>
		        <option value="AQ">Antarktika</option>
		        <option value="AG">Antigua ve Barbuda</option>
		        <option value="AR">Arjantin</option>
		        <option value="AL">Arnavutluk</option>
		        <option value="AW">Aruba</option>
		        <option value="QU">Avrupa Birliği</option>
		        <option value="AU">Avustralya</option>
		        <option value="AT">Avusturya</option>
		        <option value="AZ">Azerbaycan</option>
		        <option value="BS">Bahamalar</option>
		        <option value="BH">Bahreyn</option>
		        <option value="BD">Bangladeş</option>
		        <option value="BB">Barbados</option>
		        <option value="EH">Batı Sahara</option>
		        <option value="BZ">Belize</option>
		        <option value="BE">Belçika</option>
		        <option value="BJ">Benin</option>
		        <option value="BM">Bermuda</option>
		        <option value="BY">Beyaz Rusya</option>
		        <option value="BT">Bhutan</option>
		        <option value="ZZ">Bilinmeyen veya Geçersiz Bölge</option>
		        <option value="AE">Birleşik Arap Emirlikleri</option>
		        <option value="GB">Birleşik Krallık</option>
		        <option value="BO">Bolivya</option>
		        <option value="BA">Bosna Hersek</option>
		        <option value="BW">Botsvana</option>
		        <option value="BV">Bouvet Adası</option>
		        <option value="BR">Brezilya</option>
		        <option value="BN">Brunei</option>
		        <option value="BG">Bulgaristan</option>
		        <option value="BF">Burkina Faso</option>
		        <option value="BI">Burundi</option>
		        <option value="CV">Cape Verde</option>
		        <option value="GI">Cebelitarık</option>
		        <option value="DZ">Cezayir</option>
		        <option value="CX">Christmas Adası</option>
		        <option value="DJ">Cibuti</option>
		        <option value="CC">Cocos Adaları</option>
		        <option value="CK">Cook Adaları</option>
		        <option value="TD">Çad</option>
		        <option value="CZ">Çek Cumhuriyeti</option>
		        <option value="CN">Çin</option>
		        <option value="DK">Danimarka</option>
		        <option value="DM">Dominik</option>
		        <option value="DO">Dominik Cumhuriyeti</option>
		        <option value="TL">Doğu Timor</option>
		        <option value="EC">Ekvator</option>
		        <option value="GQ">Ekvator Ginesi</option>
		        <option value="SV">El Salvador</option>
		        <option value="ID">Endonezya</option>
		        <option value="ER">Eritre</option>
		        <option value="AM">Ermenistan</option>
		        <option value="EE">Estonya</option>
		        <option value="ET">Etiyopya</option>
		        <option value="FK">Falkland Adaları (Malvinalar)</option>
		        <option value="FO">Faroe Adaları</option>
		        <option value="MA">Fas</option>
		        <option value="FJ">Fiji</option>
		        <option value="CI">Fildişi Sahilleri</option>
		        <option value="PH">Filipinler</option>
		        <option value="PS">Filistin Bölgesi</option>
		        <option value="FI">Finlandiya</option>
		        <option value="FR">Fransa</option>
		        <option value="GF">Fransız Guyanası</option>
		        <option value="TF">Fransız Güney Bölgeleri</option>
		        <option value="PF">Fransız Polinezyası</option>
		        <option value="GA">Gabon</option>
		        <option value="GM">Gambia</option>
		        <option value="GH">Gana</option>
		        <option value="GN">Gine</option>
		        <option value="GW">Gine-Bissau</option>
		        <option value="GD">Granada</option>
		        <option value="GL">Grönland</option>
		        <option value="GP">Guadeloupe</option>
		        <option value="GU">Guam</option>
		        <option value="GT">Guatemala</option>
		        <option value="GG">Guernsey</option>
		        <option value="GY">Guyana</option>
		        <option value="ZA">Güney Afrika</option>
		        <option value="GS">Güney Georgia ve Güney Sandwich Adaları</option>
		        <option value="KR">Güney Kore</option>
		        <option value="CY">Güney Kıbrıs Rum Kesimi</option>
		        <option value="GE">Gürcistan</option>
		        <option value="HT">Haiti</option>
		        <option value="HM">Heard Adası ve McDonald Adaları</option>
		        <option value="IN">Hindistan</option>
		        <option value="IO">Hint Okyanusu İngiliz Bölgesi</option>
		        <option value="NL">Hollanda</option>
		        <option value="AN">Hollanda Antilleri</option>
		        <option value="HN">Honduras</option>
		        <option value="HK">Hong Kong SAR - Çin</option>
		        <option value="HR">Hırvatistan</option>
		        <option value="IQ">Irak</option>
		        <option value="VG">İngiliz Virgin Adaları</option>
		        <option value="IR">İran</option>
		        <option value="IE">İrlanda</option>
		        <option value="ES">İspanya</option>
		        <option value="IL">İsrail</option>
		        <option value="SE">İsveç</option>
		        <option value="CH">İsviçre</option>
		        <option value="IT">İtalya</option>
		        <option value="IS">İzlanda</option>
		        <option value="JM">Jamaika</option>
		        <option value="JP">Japonya</option>
		        <option value="JE">Jersey</option>
		        <option value="KH">Kamboçya</option>
		        <option value="CM">Kamerun</option>
		        <option value="CA">Kanada</option>
		        <option value="ME">Karadağ</option>
		        <option value="QA">Katar</option>
		        <option value="KY">Kayman Adaları</option>
		        <option value="KZ">Kazakistan</option>
		        <option value="KE">Kenya</option>
		        <option value="KI">Kiribati</option>
		        <option value="CO">Kolombiya</option>
		        <option value="KM">Komorlar</option>
		        <option value="CG">Kongo</option>
		        <option value="CD">Kongo Demokratik Cumhuriyeti</option>
		        <option value="CR">Kosta Rika</option>
		        <option value="KW">Kuveyt</option>
		        <option value="KP">Kuzey Kore</option>
		        <option value="MP">Kuzey Mariana Adaları</option>
		        <option value="CU">Küba</option>
		        <option value="KG">Kırgızistan</option>
		        <option value="LA">Laos</option>
		        <option value="LS">Lesotho</option>
		        <option value="LV">Letonya</option>
		        <option value="LR">Liberya</option>
		        <option value="LY">Libya</option>
		        <option value="LI">Liechtenstein</option>
		        <option value="LT">Litvanya</option>
		        <option value="LB">Lübnan</option>
		        <option value="LU">Lüksemburg</option>
		        <option value="HU">Macaristan</option>
		        <option value="MG">Madagaskar</option>
		        <option value="MO">Makao S.A.R. Çin</option>
		        <option value="MK">Makedonya</option>
		        <option value="MW">Malavi</option>
		        <option value="MV">Maldivler</option>
		        <option value="MY">Malezya</option>
		        <option value="ML">Mali</option>
		        <option value="MT">Malta</option>
		        <option value="IM">Man Adası</option>
		        <option value="MH">Marshall Adaları</option>
		        <option value="MQ">Martinik</option>
		        <option value="MU">Mauritius</option>
		        <option value="YT">Mayotte</option>
		        <option value="MX">Meksika</option>
		        <option value="FM">Mikronezya Federal Eyaletleri</option>
		        <option value="MD">Moldovya Cumhuriyeti</option>
		        <option value="MC">Monako</option>
		        <option value="MS">Montserrat</option>
		        <option value="MR">Moritanya</option>
		        <option value="MZ">Mozambik</option>
		        <option value="MN">Moğolistan</option>
		        <option value="MM">Myanmar</option>
		        <option value="EG">Mısır</option>
		        <option value="NA">Namibya</option>
		        <option value="NR">Nauru</option>
		        <option value="NP">Nepal</option>
		        <option value="NE">Nijer</option>
		        <option value="NG">Nijerya</option>
		        <option value="NI">Nikaragua</option>
		        <option value="NU">Niue</option>
		        <option value="NF">Norfolk Adası</option>
		        <option value="NO">Norveç</option>
		        <option value="CF">Orta Afrika Cumhuriyeti</option>
		        <option value="UZ">Özbekistan</option>
		        <option value="PK">Pakistan</option>
		        <option value="PW">Palau</option>
		        <option value="PA">Panama</option>
		        <option value="PG">Papua Yeni Gine</option>
		        <option value="PY">Paraguay</option>
		        <option value="PE">Peru</option>
		        <option value="PN">Pitcairn</option>
		        <option value="PL">Polonya</option>
		        <option value="PT">Portekiz</option>
		        <option value="PR">Porto Riko</option>
		        <option value="RE">Reunion</option>
		        <option value="RO">Romanya</option>
		        <option value="RW">Ruanda</option>
		        <option value="RU">Rusya Federasyonu</option>
		        <option value="SH">Saint Helena</option>
		        <option value="KN">Saint Kitts ve Nevis</option>
		        <option value="LC">Saint Lucia</option>
		        <option value="PM">Saint Pierre ve Miquelon</option>
		        <option value="VC">Saint Vincent ve Grenadinler</option>
		        <option value="WS">Samoa</option>
		        <option value="SM">San Marino</option>
		        <option value="ST">Sao Tome ve Principe</option>
		        <option value="SN">Senegal</option>
		        <option value="SC">Seyşeller</option>
		        <option value="SL">Sierra Leone</option>
		        <option value="SG">Singapur</option>
		        <option value="SK">Slovakya</option>
		        <option value="SI">Slovenya</option>
		        <option value="SB">Solomon Adaları</option>
		        <option value="SO">Somali</option>
		        <option value="LK">Sri Lanka</option>
		        <option value="SD">Sudan</option>
		        <option value="SR">Surinam</option>
		        <option value="SY">Suriye</option>
		        <option value="SA">Suudi Arabistan</option>
		        <option value="SJ">Svalbard ve Jan Mayen</option>
		        <option value="SZ">Svaziland</option>
		        <option value="RS">Sırbistan</option>
		        <option value="CS">Sırbistan-Karadağ</option>
		        <option value="CL">Şili</option>
		        <option value="TJ">Tacikistan</option>
		        <option value="TZ">Tanzanya</option>
		        <option value="TH">Tayland</option>
		        <option value="TW">Tayvan</option>
		        <option value="TG">Togo</option>
		        <option value="TK">Tokelau</option>
		        <option value="TO">Tonga</option>
		        <option value="TT">Trinidad ve Tobago</option>
		        <option value="TN">Tunus</option>
		        <option value="TC">Turks ve Caicos Adaları</option>
		        <option value="TV">Tuvalu</option>
		        <option value="TM">Türkmenistan</option>
		        <option value="UG">Uganda</option>
		        <option value="UA">Ukrayna</option>
		        <option value="OM">Umman</option>
		        <option value="UY">Uruguay</option>
		        <option value="QO">Uzak Okyanusya</option>
		        <option value="JO">Ürdün</option>
		        <option value="VU">Vanuatu</option>
		        <option value="VA">Vatikan</option>
		        <option value="VE">Venezuela</option>
		        <option value="VN">Vietnam</option>
		        <option value="WF">Wallis ve Futuna</option>
		        <option value="YE">Yemen</option>
		        <option value="NC">Yeni Kaledonya</option>
		        <option value="NZ">Yeni Zelanda</option>
		        <option value="GR">Yunanistan</option>
		        <option value="ZM">Zambiya</option>
		        <option value="ZW">Zimbabve</option>
          </select>
      </div>



    </div>

      <div className='col-12 mt-2'>
          <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6" style={{overflowY:"hidden",overflowX:"scroll",whiteSpace:"nowrap",flexWrap:"nowrap"}}>
              <li className="nav-item" style={{marginBottom:0}}>
                  <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_1"
                  >
                      Tüm Maçlar
                  </a>
              </li>
              <li className="nav-item" style={{marginBottom:0}}>
                  <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_2"
                  >
                      Güvenli Maçlar
                  </a>
              </li>
              <li className="nav-item" style={{marginBottom:0}}>
                  <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_3"
                  >
                      Orta Derece
                  </a>
              </li>
              <li className="nav-item" style={{marginBottom:0}}>
                  <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_4"
                  >
                      Riskli Maçlar
                  </a>
              </li>
              <li className="nav-item" style={{marginBottom:0}}>
                  <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_5"
                  >
                      Benim Maçlarım
                  </a>
              </li>
          </ul>
          <div className="tab-content" id="myTabContent" style={{marginTop:'-2vh'}}>
              <div className="tab-pane fade active show" id="kt_tab_pane_1" role="tabpanel">
                  <table className="table table-row-dashed table-row-gray-300 gy-7">
                      <thead>
                      <tr className="d-none fw-bolder fs-6 text-gray-800">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                      </tr>
                      </thead>

                      <tbody id="insertMatches_Area_1">

                      </tbody>
                  </table>
                  <div id="waitBadge"><div id="pleaseWait_badge" className="alert alert-primary d-flex align-items-center p-5 mb-10">
                      <span className="svg-icon svg-icon-2hx svg-icon-primary me-3"><span className="spinner-border spinner-border-xl align-middle"></span></span>

                      <div className="d-flex flex-column">
                          <h5 className="mt-2">Maç analizi yükleniyor, lütfen bekleyin..</h5>
                      </div>
                  </div></div>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                  <table className="table table-row-dashed table-row-gray-300 gy-7">
                      <thead>
                      <tr className="d-none fw-bolder fs-6 text-gray-800">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                      </tr>
                      </thead>
                      <tbody id="insertMatches_Area_2">

                      </tbody>
                  </table>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                  <table className="table table-row-dashed table-row-gray-300 gy-7">
                      <thead>
                      <tr className="d-none fw-bolder fs-6 text-gray-800">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                      </tr>
                      </thead>
                      <tbody id="insertMatches_Area_3">

                      </tbody>
                  </table>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
                  <table className="table table-row-dashed table-row-gray-300 gy-7">
                      <thead>
                      <tr className="d-none fw-bolder fs-6 text-gray-800">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                      </tr>
                      </thead>
                      <tbody id="insertMatches_Area_4">

                      </tbody>
                  </table>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                  <table className="table table-row-dashed table-row-gray-300 gy-7">
                      <thead>
                      <tr className="d-none fw-bolder fs-6 text-gray-800">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                      </tr>
                      </thead>
                      <tbody id="insertMatches_Area_5">

                      </tbody>
                  </table>
              </div>

          </div>


      </div>

    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
    // const intl = useIntl()
    return (
      <>
        <PageTitle breadcrumbs={[]}>Bülten</PageTitle>
        <DashboardPage />
      </>
    )
  }
  
  export {DashboardWrapper}
