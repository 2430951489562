/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import $ from 'jquery';
import clsx from 'clsx'
import {Link, Navigate} from 'react-router-dom'
import {useAuth} from '../core/Auth'

export function Login() {
  const validateEmail = (email:any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  var loginSession = 0;
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  function testLogin() {
    const loginform_email = $('#loginform_email').val();
    const loginform_pass = $('#loginform_pass').val();
    loginSession = 1;
    $('#kt_sign_in_submit').html(`<span className='indicator-progress' style={{display: 'block'}}>
    Lütfen bekleyin...
    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
  </span>`);

  if(loginform_email && loginform_pass && validateEmail(loginform_email) && loginform_pass.toString().length > 1 /*degisecek*/) {

    fetch("https://enesaydogan.com.tr/analiz/api/regapi.php?type=logUser_f&email="+loginform_email+"&password="+loginform_pass, {
      "method": "GET"
    })
        .then(response => response.json())
        .then(response => {
            if(response.status) {
              const staticJson = {"id":2,"first_name":"German","last_name":"Stark","email":"admin@demo.com","email_verified_at":"2022-07-14T11:37:39.000000Z","created_at":"2022-07-14T11:37:39.000000Z","updated_at":"2022-07-14T11:37:39.000000Z","api_token":"$2y$10$lzYGs3CVjxdlR2ERLfZOyezaXM8qXLGd5fHEkjoBmDxznEl.CvAdC"}
              saveAuth(staticJson);
              setCurrentUser(undefined);
              $('#alertInsert_area').removeAttr("style");
              $('#alertInsert_class').removeClass("alert-danger").addClass("alert-success");
              $('#alertInsert_message').html(response.message);
              setTimeout(() => {
                $('#alertInsert_class').removeClass("alert-success").addClass("alert-danger");
                $('#alertInsert_area').fadeOut(500);
                window.location.href = "/dashboard";

              }, 2500);
            } else {
              
              $('#kt_sign_in_submit').html(`<span className='indicator-label'>Giriş Yap</span>`);
              loginSession = 0;
              $('#alertInsert_area').removeAttr("style");
              $('#alertInsert_message').html(response.message);
              setTimeout(() => {
                $('#alertInsert_area').fadeOut(500);
              }, 2500);
            }
        })
        .catch(err => { console.log(err);
      });

  } else {
    $('#alertInsert_message').html("Geçersiz E-posta Adresi ya da Şifre.");
    $('#alertInsert_area').removeAttr("style");
    $('#kt_sign_in_submit').html(`<span className='indicator-label'>Giriş Yap</span>`);
    loginSession = 0;
  }

  }

  return (
    <form
      className='form w-100'
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Hesabınıza Giriş Yapın</h1>
      </div>
      
      <div id="alertInsert_area" style={{display:"none"}}>
        <div id="alertInsert_class" className="alert alert-danger d-flex align-items-center p-5 mb-10">
            <div className="d-flex flex-column">
              <span id="alertInsert_message"></span>
            </div>
        </div>
      </div>


      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>E-Posta Adresi</label>
        <input
          id='loginform_email'
          placeholder='E-Posta adresi giriniz'
          className={'form-control bg-transparent'}
          type='email'
          name='email'
          autoComplete='off'
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Şifre</label>
        <input
          id='loginform_pass'
          placeholder='Şifre giriniz'
          type='password'
          autoComplete='off'
          className={'form-control bg-transparent'}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Şifreni mi unuttun ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <span
          id='kt_sign_in_submit'
          className='btn btn-primary'
          onClick={testLogin}
        >
          <span className='indicator-label'>Giriş Yap</span>
        </span>
      </div>
      {/* end::Action */}


      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Üyemiz değil misiniz?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Kayıt Olun
        </Link>
      </div>
    </form>
  )
}
