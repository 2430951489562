/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {getLayoutFromLocalStorage, ILayout, LayoutSetup} from '../../../_metronic/layout/core'

const FavPage: React.FC = () => {
  return (
    <>
      <div className='col-12 mt-2' style={{display:"flex",alignItems:"center",justifyContent:"space-between",textAlign:"center",borderBottom:"1px dashed #80808040",paddingBottom:"11px"}}>
        <div className='col-4'>
          <img src={"https://seeklogo.com/images/F/fenerbahce-spor-kulubu-5-yildizli-arma-logo-64F337AD4A-seeklogo.com.png"} style={{width:'10vw'}}/>
        </div>
        <div className='col-4'>
          Fenerbahçe
        </div>
        <div className='col-4'>
          <KTSVG path="/media/icons/duotune/general/gen037.svg" className="svg-icon-success svg-icon-2x" />
        </div>
      </div>

    </>
  )
}

export {FavPage}
